import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const Craig = () => {
  return (
    <Layout>
      <Head title="James Blake" />
      <h3>James Blake</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        London, UK
      </h4>

      <p>
        <OutboundLink href="https://www.jamesblakemusic.com/">
          Website
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://www.instagram.com/jamesblake/">
          Instagram
        </OutboundLink>
      </p>
      <p>
        James Blake Litherland is an English singer, songwriter, and record
        producer. He first gained recognition following the release of three
        extended plays (EPs)—The Bells Sketch, CMYK and Klavierwerke—in 2010. He
        signed with A&M Records to release his self-titled debut album (2011)
        the following year, which was met with critical praise and peaked within
        the top ten of the UK Albums Chart. He departed the label in favour of
        Republic Records, through which he released his second album, Overgrown
        (2013) to further critical and commercial success; it likewise peaked
        within the chart's top ten and moderately entered the US Billboard 200.
        His third album The Colour in Anything (2016) was followed by his
        fourth, Assume Form (2019), which became his highest-charting release on
        both charts. His fifth and sixth albums, Friends That Break Your Heart
        (2021) and Playing Robots Into Heaven (2023) followed thereafter.
      </p>

      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/6p6PcFFUm5I?si=p_RXvuZVNME5SQSR"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/P3Xx_vF5n3s?si=XDO9aZvqms6k9UZ-"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/JRUjtalz_1k?si=jyPffh_wPMFIqy-p"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/em4joakjkv4?si=fhwvnYdSnL-lj5t5"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/rqScKIZaurE?si=3L3elhOjGqc6aEBw"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </Layout>
  );
};

export default Craig;
